var Request = function() {

    var _request = function (method, url, data, headers) {

        return $.ajax({
            url: url,
            type: method,
            headers: headers,
            data: data,
            dataType: 'json'
        }).done(function (response, status, xhr) {
            console.log('request success', response);
            if (response.hasOwnProperty('error')) {
                if (response.error === 'access_denied' || response.error === 'invalid_request') {
                    UIToastr.init('error', 'Session expired.', 'Message');
                    setTimeout(function(){
                        window.location.href = '/auth/login';
                    }, 2000);
                }
            }
            return response;
        }).fail(function(xhr, textResponse, err){
            console.log('request failed');
            return textResponse;
        });
    };

    return {
        get : function(url, data) {
            return _request('GET', url, data);
        },
        post : function (url, data) {
            return _request('POST', url, data);
        },
        put: function (url, data) {
            return _request('PUT', url, data);
        },
        delete: function (url, data) {
            return _request('DELETE', url, data);
        }
    }
}();